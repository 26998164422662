// PMA Colors
$text-primary:  #0A4797;
$text-primary-hover:  #0d53b0;
$text-primary-lightbg:  #0C4098;
$mm-primary:  #AD0C62;
$mm-primary-disabled:  #c4c4c4;
$mm-secondary:  #621C43;
$mm-success:  #EA600E;
$text-light-gray:  #F8F8F8;
$text-light-gray-hover:  #d3d2d2e7;
$text-gray:  #545454;
$gray-bg:  #F8F8F8;
$bg-white:  #fff;
$bg-light-primary: #8FAADC;
$gray-border:  #DADADA;
$bluish: #273B4A;
$bg-light: #F3F3F3;
// $bg-white: #fff;
$text-default: #8A8A8A;
$text-black: #000000;
$card-hover: #EFEEEE;


// New Variables
// New Colors

// Primary-colors
$primary: #0A4797;
$secondary: #545454;
$Tertiary: #8A8A8A;
$whitecolor: #FFFFFF;

// other-colors
$LightGray:  #F8F8F8;
$Gray85: #D9D9D9;
$Gray62: #9E9E9E;
$LightShadeGray: #DADADA;

// font-familys

$lato-fontfamily: 'Lato', sans-serif;

// text-sizes-section

$large-title: 30px;
$Heading: 24px;
$Heading-2: 18px;
$Sub-Headline: 16px;
$Body-Text: 14px;
$Small-Text: 13px;

//styles scc
$goog-te-combo-ced4da: #ced4da;
// PMA Font Sizes
$f-8: 8px;
$f-12: 12px;
$f-13: 13px;
$f-14: 14px;
$f-15: 15px;
$f-16: 16px;
$f-17: 17px;
$f-18: 18px;
$f-19: 19px;
$f-20: 20px;    
$f-22: 22px;    
$f-24: 24px;
$f-25: 25px;
$f-30: 30px;
$f-35: 35px;
$f-36: 36px;

// PMA Font Weight
$fw-200: 200;
$fw-300: 300;
$fw-400: 400;
$fw-500: 500;
$fw-600: 600;
$fw-700: 700;

// PMA Radius
$br-10: 10px;

// PMA Paddings
$padd-10: 10px;
$padd-20: 20px;
$padd-30: 30px;


// PMA 0 Values
$value-0: 0px;

// PMA Cursors
$pointer:pointer;

// @mixin buttons{
//     font-size: $f-13 !important;
//     border-radius: 5px !important;
//     color: $new-Tertiary !important;
//     background-color: $new-primary !important;
// }
@mixin button-block {
    padding: 16px 0px!important;  //top bottom and left right
    font-size: $Sub-Headline !important;
    background-color: $primary !important;
    border-radius: $br-10 !important;
    font-weight: $fw-300 !important;
    font-family: $lato-fontfamily !important;
    color: $whitecolor !important;
    border: 1px solid $primary !important;
    cursor: pointer !important;
    width: 100% !important;
    
}
@mixin strip-cont {
    border-radius: $br-10;
    background-color: $text-primary;
    padding: 18px !important;
    cursor: $pointer;
    position: relative !important;
    white-space: nowrap;
    font-family: $lato-fontfamily !important;
    
}

//login component variables

$card-f2f3f8: #f2f3f8;
$login-heading-212529: #212529;
$btn-blue-0b4da0: #0b4da0;
$btn-blue-hover-0069d9: #0069d9;
$btn-blue-hover-0062cc: #0062cc;
$input-placeholder-a4a4a4: #a4a4a4;
$body-03003b: #03003b;
$btn-login-1a1db2: #1a1db2;
$form-label-group-777: #777;

//splash screen component
$-h4-rgba-00001: rgba(0, 0, 0, 0.1);

// credential accepted component 
$trash-i-E70808: #E70808;
$trash-rgba-00002: rgba(0, 0, 0, 0.2);

// data attribute history
$edit-rgba00003: rgba(0, 0, 0, 0.3);

// language selection component 
$f-14-B4B3B3: #B4B3B3;

// profile view component 
$yesbtn-1C4DA1: #1C4DA1;
$nobtn-4472C4: #4472C4;