/* You can add global styles to this file, and also import other style files */
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material'as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$pdvmanger-primary: mat.define-palette(mat.$indigo-palette);
$pdvmanger-accent : mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$pdvmanger-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$pdvmanger-theme: mat.define-light-theme((color: (primary: $pdvmanger-primary,
      accent: $pdvmanger-accent,
      warn: $pdvmanger-warn,
    )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($pdvmanger-theme);


// PDV Variables
@import "./assets/sass/custom/variables";


/* You can add global styles to this file, and also import other style files */
body {
  // font-family: 'Poppins' !important;
  font-family     : $lato-fontfamily !important;
  background-color: $bg-white !important;
  top             : 0px !important;
}


h1.pma-heading {
  font-size  : $f-24;
  line-height: 29px;
  font-weight: $fw-500 !important;
  font-family: $lato-fontfamily !important;
  color      : $text-gray;
}

.text-gray {
  color: $text-gray;
}

.img-ind {
  z-index       : 999 !important;
  margin-top    : 0px !important;
  margin-right  : 10px;
  // margin-left: 10px !important;
}

.padding-to-inpu {
  padding-left: 10px !important;
  font-size   : $f-14 !important;
}

.padding-to-inpu:focus {
  border-color: none !important;
  outline     : none !important;
  box-shadow  : none !important;
}

// Font Family
.mat-h1,
.mat-headline,
.mat-typography .mat-h1,
.mat-typography .mat-headline,
.mat-typography h1,
.mat-form-field,
.mat-form-field {
  font-family: $lato-fontfamily !important;
}


// Mat Inputs modification
.mat-input-element {
  caret-color     : $text-primary;
  // border: 0px !important;
  // border-bottom: 1px solid rgba(0, 0, 0, 0.17) !important;
}

// Google Translate
#google_translate_element {
  flex          : auto !important;
  flex-direction: column;
}

.goog-te-combo {
  display            : block;
  width              : 100%;
  padding            : 1.1rem 1.25rem 1.1rem 0.85rem;
  -moz-padding-start : calc(0.75rem - 3px);
  font-size          : 1rem;
  font-weight        : $fw-400;
  line-height        : 1.5;
  color              : $login-heading-212529;
  background-color   : $text-light-gray !important;
  background-image   : url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat  : no-repeat;
  background-position: right 0.75rem center;
  background-size    : 16px 12px;
  border             : 1px solid $goog-te-combo-ced4da;
  border-radius      : 0.625rem;
  transition         : border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  -webkit-appearance : none;
  -moz-appearance    : none;
  appearance         : none;
}

body#lang-body .goog-te-gadget {
  font-family    : arial !important;
  font-size      : 11px !important;
  color          : #666 !important;
  padding        : 50px 30px !important;
  display        : flex !important;
  justify-content: left !important;
  align-items    : left !important;
  white-space    : nowrap !important;
  flex-direction : column !important;
  margin-top     : 100px !important;
  padding-right  : 25px !important;
  padding-left   : 25px !important;
  padding-top    : 20px !important;
}



.goog-te-gadget {

  padding        : 0px 30px;
  display        : flex;
  justify-content: left;
  align-items    : left;
  white-space    : nowrap;
  height         : 40px;
  flex-direction : column;
  margin-top     : 0px;
  padding-right  : 15px;
  padding-left   : 15px;
  padding-top    : 0px;
  padding-bottom : 0px;
}

.goog-te-gadget {
  color: transparent !important;
}

.goog-logo-link {
  display: none !important;
}

.profile-edit {
  mat-label {
    font-size: $f-14 !important;
  }
}




// body#lang-body .goog-te-gadget {
//   font-family: arial !important;
//   font-size: 11px !important;
//   color: #666 !important;
//   padding: 50px 30px !important;
//   display: flex !important;
//   justify-content: left !important;
//   align-items: left !important;
//   white-space: nowrap !important;
//   flex-direction: column !important;
//   margin-top: 100px !important;
//   padding-right: 25px !important;
//   padding-left: 25px !important;
//   padding-top: 20px !important;
// }

html,
body {
  height     : 100%;
  font-family: $lato-fontfamily !important;
}

body {
  margin     : 0;
  font-family: $lato-fontfamily !important;
}

.mouse-pointer {
  cursor: pointer;
}


// Buttons
// btn-lg
.btn-lg,
.btn-group-lg>.btn {
  padding: 1.15rem !important;
}

.pma-buttons {
  margin-bottom: 10px !important;

  .btn-mm-p {
    background-color: $text-primary;
    font-size       : $f-16;
    cursor          : $pointer;
    border-radius   : $br-10;
    color           : $bg-white;
    font-weight     : $fw-500;
    padding         : 13px !important;
    width           : 100%;

    &:hover {
      opacity: 0.9;
    }
  }


  .btn-login {
    background   : $btn-login-1a1db2;
    border       : none;
    padding      : 10px;
    color        : $bg-white;
    position     : absolute;
    width        : 157px;
    height       : 47px;
    cursor       : pointer;
    background   : $btn-login-1a1db2;
    border-radius: 6px;
    bottom       : 10px;
  }

  .btn-p-s {
    background-color: $text-primary;
    font-size       : $f-14;
    cursor          : $pointer;
    padding         : 12px 24px;
    border-radius   : $br-10;
    color           : $bg-white;
    font-weight     : $fw-300;
    font-family     : $lato-fontfamily;

    &:hover {
      background-color: $text-primary-hover;
      color: $bg-white;
    }
  }

  .btn-p {
    background-color: $text-primary;
    font-size       : $f-14;
    cursor          : $pointer;
    border-radius   : $br-10;
    color           : $bg-white !important;
    font-weight     : $fw-300;
    line-height     : 17px;
    font-family     : $lato-fontfamily;
    // height: 35px !important;
    // width: 115px !important;
    padding         : 10px 39px !important;


    &:hover {
      background-color: $text-primary-hover;
      color: $bg-white;
    }
  }

  .btn-l {
    // background-color: $text-light-gray;
    font-size       : $f-14 !important;
    border-radius   : $br-10 !important;
    line-height     : 17px !important;
    font-family     : $lato-fontfamily;
    padding         : 10px 39px !important;
    color           : $text-gray !important;
    font-weight     : $fw-300;
    background-color: $text-light-gray;

    &:hover {
      background-color: $text-light-gray-hover;
    }
  }

  .btn:focus {
    outline   : 0 !important;
    box-shadow: none !important;
  }



  .btn-space {
    li {
      margin-bottom: 15px;
    }
  }

  // .btn-sm {
  //   padding: 0.8rem 1.5rem !important
  // }
}

.mat-form-field-appearance-fill .mat-form-field-flex,
.mat-form-field {
  width           : 100%;
  background-color: $bg-white;
}


.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: $text-primary !important;
}

.form-control:focus {
  box-shadow: none !important;
  outline   : 0;
}

// Blue Strip
.b-pro-strip {
  @include strip-cont;

  // .strip-cont{
  //   width: 50px!important;
  //   height: 50px !important;
  // }
  // .pdv-logo-icon{
  //   position: absolute;
  //   right: 26px;
  //   top: 29px!important;
  // }
  h2 {
    font-size     : $f-18;
    margin-bottom : 0px;
    padding-bottom: 0px;
    font-weight   : bold;
    font-family   : $lato-fontfamily !important;
    line-height   : 20px;
  }

  h6 {
    font-size    : $f-16;
    font-weight  : lighter;
    margin-bottom: $value-0;
    color        : $whitecolor;
    font-family  : $lato-fontfamily !important;
  }

  h5 {
    font-weight: $fw-200 !important;
    font-size  : $f-14;
    color      : $whitecolor;
    font-family: $lato-fontfamily !important;
    margin     : 0px !important;


  }

}

.btn-space {
  position: fixed !important;
  bottom  : 14px !important;
}

// Modal
.modal-backdrop {
  z-index: 0 !important;
}

.modal-header {
  border: none !important;
}

.modal-content {
  border-radius: 20px !important;
  box-shadow   : 0px 0px 10px rgba(0, 0, 0, 0.1);

  h2 {
    font-family: $lato-fontfamily !important;
    font-weight: $fw-500 !important;
    font-size  : $f-16 !important;
    line-height: 19px !important;
    text-align : center !important;
    color      : $text-gray !important;
  }

  h3 {
    font-weight: $fw-300;
    font-size  : $f-16;
    line-height: 19px;
    font-family: $lato-fontfamily !important;
  }
}

.modal-title {
  font-weight: $fw-500 !important;
  font-size  : $f-16 !important;
  line-height: 19px !important;
  font-family: $lato-fontfamily !important;
}

.modal {
  .mat-form-field-appearance-fill .mat-form-field-flex {
    padding: 0px !important;
  }
}

.btn-close {
  font-size: 1.5rem !important;
}


.text-blue {
  color: $text-primary !important;
}

.text-graylight {
  color: $text-gray !important;
}

// Language inner hide
.goog-te-banner-frame,
#goog-gt-tt,
.skiptranslate {
  display: none !important;
}

.crosshairs .text-icon {
  font-size: $f-15 !important;
  color    : $text-primary !important;
}

.lang-enable .skiptranslate {
  display: block !important;
}

.mouse-not-allowed {
  cursor: not-allowed !important;
}

//override class
.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5,
.mat-h4,
.mat-subheading-1,
.mat-typography .mat-h4,
.mat-typography .mat-subheading-1,
.mat-typography h4 {
  font-family: $lato-fontfamily !important;
}

// .submit-btn{
//   @include buttons;
// }

.pdv-button-pro {
  @include button-block;

}

// this is for meta fields nameas and identify as
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label {
  font-weight: $fw-300;
  font-size  : 14px !important;
  line-height: 17px;

}

// this is for lastupdated on field
.mat-label {
  font-weight: $fw-400;
  font-size  : 16px;
  line-height: 111.54%;
  color      : $text-gray;
}

// this is for gender select fields
.mat-select-value {
  font-weight: $fw-400;
  font-size  : 14px;
  line-height: 17px;
  color      : $text-gray;
}

// .mat-form-field-appearance-fill .mat-form-field-flex {
//   padding: 0px !important;
// }
// .mat-form-field-wrapper{
//   padding: 0px !important;
// }
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: $whitecolor;
}

.survey-bg {
  background-color: $gray-bg;
  border-radius   : $br-10 !important;
  padding-top     : 0px;
  padding-bottom  : 0px;

  .card-header,
  .card-body {
    background-color: $text-light-gray;
    border          : none !important;
    border-radius   : 10px !important;


    h4 {
      font-size     : $f-14;
      color         : $text-primary;
      margin-bottom : 0px;
      font-weight   : $fw-700;
      // font-family: $lato-fontfamily !important;
      line-height   : 17px;
      text-align    : end;
    }

    h3 {
      font-family  : $lato-fontfamily !important;
      // font-style: normal;
      font-weight  : $fw-400;   
      font-size     : $f-14;
      color         : $text-primary;
      // line-height: 17px;
    }

    strong {
      font-family  : $lato-fontfamily !important;
      // font-style: normal;
      font-weight  : $fw-700;
      font-size    : $f-14;

    }
  }

  .card-body {
    p {
      margin-bottom: 0px;
      margin-top   : 0px;
      font-size    : $f-14;
    }

    .d-flex {
      padding-bottom: 13px;
    }

    strong {
      color: $text-default;
    }
  }

  .card-footer {
    border-top: 1px solid $gray-border;
    padding   : 5px;

    h4 {
      padding-bottom: $value-0;
      margin-bottom : $value-0;
      font-size     : $f-14;
      color         : $text-gray;
    }

    i {
      padding-right: 5px;
    }

    .border-right {
      border-right: 1px solid $gray-border;
    }
  }

  .primary-link {
    color: $text-primary !important;
  }
}

.mat-form-field-appearance-fill .mat-form-field-underline:before {
  background-color: rgba(0, 0, 0, 0.17) !important;
}

// Profile Tilte
.p-title {
  .list-style-1 {
    text-align: left;

    li {
      font-size     : $f-14;
      font-weight   : $fw-300;
      padding-bottom: 15px;
      color         : $text-gray;
      line-height   : 111.54%;
    }
  }

  .list-style-2 {
    text-align: right;

    li {
      font-weight: $fw-400 !important;
      color      : $text-gray;
      font-size  : $f-14;
      line-height: 17px;
    }
  }
}

.atr-val {
  width: 45% !important;

}

.data-val {
  width: 45% !important;
}

// this is fro bottom padding reduce in data list 
.padding-reduce {
  .mat-form-field-wrapper {
    padding-bottom: 10px !important;
  }
}

// this is for connections 
.connection-bg {
  background-color: $text-light-gray;
  border-radius   : $br-10 !important;

  .card-header,
  .card-body {
    background-color: $text-light-gray;
    border          : none !important;
    border-radius   : 10px !important;


    h4 {
      font-size  : $f-14;
      font-weight: $fw-700;
      line-height: 17px;
      font-weight: 700;
      color      : $text-primary;
    }

    h3 {
      font-family: $lato-fontfamily !important;
      font-weight: $fw-700;
      font-size  : $f-14;
      line-height: 17px;
      color      : $text-default;
    }
  }
}

// this is for transaction sorttby order desending order 
.mat-select-panel {
  min-width : calc(100% + 24px) !important;
  padding   : 12px;
  position  : relative;
  background: $whitecolor !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  left: 4px;
}

// .mat-select-panel {
//   min-width  : calc(100% + 24px) !important;
//   max-height: 377px !important;
// }

.trasaction-margin {
  .mat-select-value-text {
    margin-left: 20px;
  }


}

// this is for credentaials list reducing padding bottom
.Credential-list-padding-redu {
  .mat-form-field-wrapper {
    padding-bottom: 0px !important;
  }
}

// .padding-reduce{
//   .mat-input-element {
//     width: 35% !important;
//   }


// }
.mat-select-panel .mat-optgroup-label,
.mat-select-panel .mat-option {
  // font-weight: 400 !important;
  // font-size: 14px !important;
  // line-height: 17px !important;
  // color: $text-default !important;
  font-weight: 400 !important;
  font-size  : 16px !important;
  line-height: 35px !important;
  color      : $text-black;
  height     : 100% !important;
  background : none !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: $text-gray !important;

  .mat-select-panel .mat-optgroup-label,
  .mat-select-panel .mat-option {
    font-weight: 400 !important;
    font-size  : 14px !important;
    line-height: 17px !important;
    color      : #8A8A8A !important;
  }

  .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: #545454 !important;
  }

  // .cdk-overlay-pane{
  //   top: 50px !important;
  // }


  // profile all scroll 
  .last-update-cont {
    height: 35vh;
  }

  .myPanelClass {
    margin-top: 30px !important;
  }

  // this is for matcheckbox style in mat-select transactions 
  .first-filter-cont {
    .mat-checkbox-frame {
      border       : 1px solid $text-black;
      border-radius: 6px;
    }
  }

  .myPanelClass {
    margin-top: 30px !important;
  }
}

.VIpgJd-ZVi9od-l4eHX-hSRGPd {
  display: none !important;
}

.qr-code{
  
  // .mat-form-field-appearance-fill .mat-form-field-flex{
  //   padding: 0.75em 0.75em 0 0em;
  // }
  .mat-form-field-type-mat-select .mat-form-field-label{
    font-weight: 700;
  }

  .form-control{
    border: none;
    border-bottom: 1px solid #ced4da;
    border-radius: 0px;
    font-size: 16px !important;
    font-weight: bold;
  }
  .form-control:focus{
    border-color: $text-primary;
  }
}
